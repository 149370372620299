import React from 'react';

import ProjectPreviewView from '../../components/ProjectPreviewView';
import Layout from '../../layout/CompanyLayout';

const IndexPage = (): React.ReactElement => {
  return (
    <Layout>
      <ProjectPreviewView></ProjectPreviewView>
    </Layout>
  );
};

export default IndexPage;
